<template>
  <vs-row>
    <vs-col class="sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
      <vx-card
        :title="lang.treeView.title[languageSelected]"
        class="overflow-hidden mb-2"
      >
        <vs-row>
          <vs-col>
            <KonaTree
              ref="konaTree"
              v-if="showTree"
              :tpl="'full'"
              :tree-data="treeData"
              :searchBar="true"
              :move="moveNode"
              :key="key"
              :read-only="isReadOnly"
            />
          </vs-col>
        </vs-row>
      </vx-card>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapState } from 'vuex'
import botTreeMixin from '@/mixins/botTreeMixin'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'TreeView',
  mixins: [botTreeMixin, staffManagementMixin],
  components: {
    KonaTree: () => import('../../../components/KonaTree.vue')
  },
  data() {
    return {
      showTree: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true)
    }
  },
  async mounted() {
    this.showTree = true
    await this.initTree(false, true) // refreshDialogs, loadVersion
  },
  destroy() {
    this.showTree = false
  }
}
</script>
