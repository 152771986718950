import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
      key: new Date().getTime(),
      treeData: []
    }
  },
  computed: {
    ...mapGetters('botMaker', [
      'bot',
      'dialogs',
      'responseValidations',
      'activeVersion'
    ]),
    ...mapGetters('integrations', ['integrations']),
  },
  methods: {
    ...mapMutations('botMaker', ['START_STORE', 'SET_BOT_ID']),
    ...mapActions('botMaker', [
      'GET_BOT',
      'GET_DIALOGS',
      'GET_INTENTIONS',
      'GET_ACTIVE_VERSION',
      'GET_BOT_VERSIONS',
      'UPDATE_DIALOG_POSITION',
      'GET_RESPONSE_VALIDATIONS'
    ]),
    ...mapActions('integrations', ['GET_INTEGRATIONS']),
    getOrdered(elements) {
      const ITER_LIMIT = 5000
      const ret = []
      let done = false
      let iter_counter = 0
      const dialogs = elements.map(a => Object.assign({}, a))
      while (!done && iter_counter <= ITER_LIMIT) {
        let aux_i = 0
        const found = dialogs.some((element, i) => {
          if (!element.next) {
            const next = element.aux_next
            delete element.aux_next
            ret.push(element)
            ret[ret.length - 1].next = next
            aux_i = i
            return true
          }
        })
        if (found) {
          dialogs.splice(aux_i, 1)
          dialogs.some(element => {
            if (element.next && element.next === ret[ret.length - 1].dialogId) {
              element.aux_next = element.next
              delete element.next
              return true
            }
          })
        } else {
          done = true
        }
        iter_counter += 1
      }
      return ITER_LIMIT < iter_counter ? [] : ret.reverse()
    },
    setPrevious(elements) {
      const dialogs = elements.map(a => Object.assign({}, a))
      dialogs.forEach((element, index) => {
        if (index > 0) {
          element.previous = dialogs[index - 1].dialogId
        }
      })
      return dialogs
    },
    buildBotTree(items, parent = '0', path = '') {
      const nested = []

      items.forEach(item => {
        // set roots parent
        if (!item.parent) item.parent = '0'
        if (item.parent === parent) {
          const children = this.buildBotTree(
            items,
            item.dialogId,
            `${path}.${parent}`
          )

          if (children.length) {
            item.children = this.setPrevious(this.getOrdered(children))
          }
          // const node = this.createNode(
          //   item,
          //   i > 0 && items[i - 1].parent === item.parent
          //     ? items[i - 1]
          //     : undefined
          // )
          const node = this.createNode(item, `${path}.${parent}`)
          nested.push(node)
        }
      })
      // return this.setPrevious(nested)
      return nested
    },
    getResponses(responses) {
      if (!responses) return

      let responsesTexts = []
      responses.forEach(r => responsesTexts.push(r.responseText))
      return responsesTexts.join(' | ')
    },
    getValidation(dialog) {
      if (dialog.sendValidationSettings && this.responseValidations) {
        const validationId = dialog.sendValidationSettings.validation

        const dialogValidation = this.responseValidations.find(
          v => v._id === validationId
        )
        /** Check if dialogValidation exists */
        if (dialogValidation && dialogValidation.service) {
          return dialogValidation.service.name
        } else {
          console.error('Unable to find dialogValidation: ' + validationId)
          return null
        }
      }
      return null
    },
    createNode(dialog, path) {
      // const title = dialog.condition.params.intent
      //   ? dialog.condition.params.intent
      //   : dialog.condition.params.regex
      const title = dialog.name

      return {
        _id: dialog._id,
        dialogId: dialog.dialogId,
        id: `${dialog.dialogId}`,
        title: title,
        responses: this.getResponses(dialog.responses),
        validation: this.getValidation(dialog),
        expanded: true,
        type: dialog.type,

        parent: `${dialog.parent}`,
        children: Object.assign(dialog.children),
        next: dialog.next,
        path
      }
    },
    async loadDialogs() {
      const botId = this.$route.query.bot || this.$route.query.botId
      const serviceId = this.$route.query.service || this.$route.query.serviceId
      if (botId) {
        if (
          !this.dialogs ||
          this.botId !== botId ||
          this.bot.service.id !== serviceId
        ) {
          // this.$vs.loading()
          this.START_STORE()

          this.SET_BOT_ID(botId)
          try {
            await this.GET_BOT(botId)
            if (this.bot.legacy) {
              this.$notify.warning(
                this.lang.botMaker.legacyBot[this.languageSelected]
              )
              this.$router.push('/bot-maker/bots')
            } else {
              await this.GET_ACTIVE_VERSION([
                serviceId,
                this.$route.query.version
              ])
              await this.GET_BOT_VERSIONS([
                serviceId,
                this.$route.query.version
              ])
              if (
                this.bot.fork &&
                this.bot.fork.status &&
                this.bot.fork.status === 1
              ) {
                this.showForking = true
              } else {
                this.showBotSays = true
                this.showMobileView = true
                this.showUserSays = true
                this.showEditorNavBarOptions = true
              }
              await this.GET_INTENTIONS()
              await this.GET_DIALOGS(undefined)
            }
            // this.$vs.loading.close()
          } catch (error) {
            // this.$vs.loading.close()
            this.$refs.konaTree.stopTreeLoading()
            this.$vs.notify({
              title: this.lang.botMaker.errorTitle[this.languageSelected],
              text: this.lang.botMaker.error[this.languageSelected],
              color: 'danger'
            })
            this.$log.error(error)
            return await Promise.reject(error)
          }

          return
        }
      }
    },
    async initTree(refreshDialogs = false, loadingVersion = false) {
      if (!this.dialogs || refreshDialogs) {
        await this.loadDialogs()
      }
      try {
        // get updated dialogs
        const serviceId =
          this.$route.query.service || this.$route.query.serviceId
        if (serviceId) {
          if (!loadingVersion) {
            // this.$vs.loading()
            await this.GET_ACTIVE_VERSION([
              serviceId,
              this.$route.query.version
            ])
            await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
          }

          if (!this.integrations || this.integrations.length === 0) {
            await this.GET_INTEGRATIONS({ services: [serviceId] })
          }
          const activeIntegrations = this.integrations
            .filter(i => i.active)
            .map(i => i.knownType)
          activeIntegrations.push('all')

          // order root dialogs using property 'next' from first one without parent
          let orderedDialog = []
          orderedDialog = this.dialogs.filter(
            d => !d.parent || d.parent === '0'
          ).map(d => ({
            ...d,
            responses: d.responses.filter(r => r.platforms.some(p => activeIntegrations.includes(p)))
          }))

          orderedDialog = orderedDialog.concat(
            this.dialogs.filter(d => d.parent && d.parent !== '0')
          )

          // build bot tree data
          this.treeData = [
            {
              title: `${this.bot.service.name}`,
              expanded: true,
              children: this.setPrevious(
                this.getOrdered(this.buildBotTree(orderedDialog))
              )
            }
          ]
          // this.$vs.loading.close()
        }
      } catch (error) {
        // this.$vs.loading.close()
        this.$refs.konaTree.stopTreeLoading()
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
        this.$log.error(error)
      }
    },
    async moveNode(id, next, parent) {
      try {
        this.$vs.loading()
        await this.UPDATE_DIALOG_POSITION({
          dialogSavedId: id,
          next,
          parent
        })
        await this.initTree(true)
        this.key = new Date().getTime()
      } catch (error) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
      } finally {
        this.$vs.loading.close()
      }
    }
    // buildRoot(dialogs, dialogId, ordered) {
    //   const item = dialogs.find(d => d.dialogId === dialogId)
    //   ordered.push(item)
    //   if (item.next) {
    //     this.buildRoot(dialogs, item.next, ordered)
    //   }
    // },
  },
  watch: {
    dialogs() {
      this.initTree(false, true)
    },
    activeVersion() {
      if (this.activeVersion) {
        this.GET_RESPONSE_VALIDATIONS(this.bot.id)
      }
    }
  }
  // mounted() {
  // this.GET_RESPONSE_VALIDATIONS(this.activeVersion)
  // }
}
